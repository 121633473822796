<template>
  <v-navigation-drawer
    class="sidebar grey lighten-5"
    absolute
    permanent
    floating
    stateless
    width="240px"
  >
    <template v-slot:prepend>
      <div class="sidebar__headline headline blue-grey--text text--darken-3">{{ title }}</div>
    </template>
    <v-list>
      <template v-for="selectElement in selectElements">
        <v-divider :key="`${selectElement.pageName}-divider`" />

        <v-list-item
          :key="selectElement.pageName"
          class="sidebar-select pa-0"
          color="transparent"
          active-class="sidebar-select--active"
          :to="{ name: selectElement.pageName }"
        >
          <v-list-item-content class="sidebar-select__title subtitle-1 font-weight-bold">
            {{ selectElement.title }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SidebarSection',
  props: {
    selectElements: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  padding: 25px 25px 25px 15px;

  &__headline {
    padding-left: 5px;
    margin-bottom: 37px;
  }
}

.sidebar-select {
  cursor: pointer;

  &--active {
    .sidebar-select__title {
      color: #37474f;
    }
  }

  &__title {
    padding: 18px 5px;
    color: #90a4ae;
  }
}
</style>
