import { render, staticRenderFns } from "./SidebarSection.vue?vue&type=template&id=47359e4f&scoped=true&"
import script from "./SidebarSection.vue?vue&type=script&lang=js&"
export * from "./SidebarSection.vue?vue&type=script&lang=js&"
import style0 from "./SidebarSection.vue?vue&type=style&index=0&id=47359e4f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47359e4f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VDivider,VList,VListItem,VListItemContent,VNavigationDrawer})
