<template>
  <div class="promo-codes-page fill-height">
    <sidebar-section
      :select-elements="$options.sidebarSections"
      :title="$options.sidebarSectionsTitle"
    />
    <div class="promo-codes-page__content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import SidebarSection from '../components/common/SidebarSection.vue';

import {
  PROMO_CODE_TYPES,
  PROMO_CODES_SIDEBAR_ELEMENTS,
  PROMO_CODE_TYPE_NAMES
} from '@/constants/promo-codes';

const INITIAL_PROMO_CODES_PAGE = PROMO_CODE_TYPES.ONE_TIME;

const SIDEBAR_SECTION_ELEMENTS = Object.values(PROMO_CODES_SIDEBAR_ELEMENTS);

const SIDEBAR_SECTION_TITLE = 'Promo codes';

export default {
  name: 'PromoCodesPage',
  components: { SidebarSection },
  sidebarSections: SIDEBAR_SECTION_ELEMENTS,
  sidebarSectionsTitle: SIDEBAR_SECTION_TITLE,
  mounted() {
    const route = this.$route.matched.find(matchedRoute => {
      const { name } = matchedRoute;

      return PROMO_CODE_TYPE_NAMES.includes(name);
    });

    if (route || this.$route.name === INITIAL_PROMO_CODES_PAGE) {
      return;
    }

    this.$router.replace({ name: INITIAL_PROMO_CODES_PAGE });
  }
};
</script>

<style lang="scss" scoped>
.promo-codes-page {
  position: relative;

  &__content {
    height: 100%;
    padding-left: 240px;
  }
}
</style>
